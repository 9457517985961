import "core-js/modules/es.string.ends-with.js";
import "core-js/modules/es.array.push.js";
import { formatTimeToStr } from "@/utils/date";
import { getHcDomainByTaskId } from "@/api/biz_hc_task";
import infoList from "@/mixins/infoList";
export default {
  name: "hcDomain",
  mixins: [infoList],
  data: function data() {
    return {
      listApi: getHcDomainByTaskId,
      taskId: 0
    };
  },
  filters: {
    formatDomain: function formatDomain(data) {
      if (data != "." && data.endsWith('.')) {
        return data.substring(0, data.length - 1);
      }
      return data;
    },
    formatDate: function formatDate(time) {
      if (time != null && time != "") {
        var date = new Date(time * 1000);
        return formatTimeToStr(date, "yyyy-MM-dd hh:mm");
      } else {
        return "";
      }
    }
  },
  methods: {
    //条件搜索前端看此方法
    onSubmit: function onSubmit() {
      this.searchInfo.ID = this.taskId;
      this.page = 1;
      this.pageSize = 30;
      this.getTableData();
    },
    openDomainPage: function openDomainPage(row) {
      this.$router.push({
        name: "zoneDetail",
        query: {
          zoneId: row.zoneId,
          zone: this.$options.filters['formatDomain'](row.zoneName),
          domain: row.domain
        }
      });
    },
    loadDomainList: function loadDomainList(taskId) {
      this.tableData = [];
      this.taskId = taskId;
      this.searchInfo.ID = this.taskId;
      this.$set(this.searchInfo, 'domain', '');
      this.getTableData();
    }
  }
};